import {
    TranslocoMissingHandler,
    TranslocoMissingHandlerData,
} from '@jsverse/transloco';
import { environment } from '~/environments/environment';

export class CustomHandler implements TranslocoMissingHandler {
    handle(key: string, data: TranslocoMissingHandlerData): any {
        if (!environment?.production) {
            console.warn(`Translation for ${key} was not found in ${data.activeLang}`);
        }
        return null;
    }
}
